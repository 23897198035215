import React from 'react'
import { Flex, Icon, Text, Badge, Card, Button } from '..'

const NewTab = ({ onClick }) => (
  <div style={{ width: 180 }}>
    <Flex style={newTabDivStyle}>
      <Button noShadow labelProps={{ title: true, size: 13 }} icon='plus' style={newButtonStyle} round tooltip='NUOVA PROPOSTA' onClick={onClick} />
    </Flex>
  </div>
)

export const Tabs = ({ style, currentTab, children, onTabSelection, onNewTabClick }) => {
  return (
    <Flex style={{ ...style }}>
      <Flex row fw js wrap>
        {children?.map((child, i) => {
          const { icon, ballColor, title, badge, index, hasChanges } = child.props
          return (
            <Flex js key={'tabKey' + i} onClick={() => onTabSelection && onTabSelection(i)} row style={{ ...tabStyle, backgroundColor: currentTab === index ? 'white' : 'rgba(231, 231, 231, 0.8)' }}>
              {icon && <Icon name={icon} size={20} style={{ marginLeft: 10, marginTop: -4 }} />}
              {ballColor && <div style={{ width: 15, height: 15, backgroundColor: ballColor, borderRadius: '50%' }} />}
              {hasChanges && <Text value='❗️' style={{ marginLeft: 5, marginRight: -5 }} />}
              <Text bold title size={13} style={{ marginLeft: 10 }} value={title || ''} />
              {badge > 0 && <Badge value={badge} />}
            </Flex>
          )
        })}
        {onNewTabClick && <NewTab onClick={onNewTabClick} />}
      </Flex>
      <Flex bg='white' fw height={3} />
      <Card fadeIn style={cardStyle}>
        {children[currentTab] ? children[currentTab].props.children : null}
      </Card>
    </Flex>
  )
}

export const TabHeader = ({ children, icon, ballColor, title, badge, index }) => null

const tabStyle = {
  cursor: 'pointer',
  backgroundColor: 'rgba(231, 231, 231, 0.8)',
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  width: 180,
  height: 35,
  paddingLeft: 10,
  marginRight: 10,

  boxShadow:
    '1px -1px 3px 0 rgba(0, 0, 0, 0.2), 1px -1px 1px 0 rgba(0, 0, 0, 0.14), 2px -1px 1px -1px rgba(0, 0, 0, 0.12)'
}

const cardStyle = {
  width: '100%',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  backgroundColor: 'rgba(231, 231, 231, 0.8)'
}

const newTabDivStyle = {
  ...tabStyle,
  width: 'fit-content',
  height: 35,
  paddingLeft: 5,
  paddingRight: 5,
  fontWeight: 'normal'
}
const newButtonStyle = {
  boxShadow: 'none',
  borderTopLeftRadius: 15,
  borderTopRightRadius: 15,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  backgroundColor: 'transparent'
}
