import React from 'react'
import { Button, Card, Flex, Input, Spinner, Text } from '../../Components'
import { useNavigate, useParams } from 'react-router-dom'
import { reportTypes, saveSchedule, useSchedule } from './Functions'
import { ClientList } from './ClientList'
import { CreateCron } from './CreateCron'
import { InputSwitch } from 'primereact/inputswitch'
import { ShipOwnerList } from './ShipOwnerList'

export function Report () {
  const { scheduleId } = useParams()
  const navigate = useNavigate()
  const [schedule, setSchedule, isSuccess] = useSchedule(scheduleId)
  const { name = '', description = '', reportType = '', clientsList = [], shipOwnersList = [], shipOwnerClient = '', isActive, dayInMonth } = schedule

  const save = async () => {
    const res = await saveSchedule(schedule)
    res?._id && navigate('/cron/' + res?._id?.toString())
  }

  const setActiveSchedule = async (newValue) => {
    const res = await saveSchedule({ ...schedule, isActive: newValue })
    res?._id && navigate('/cron/' + res?._id?.toString())
  }

  if (!isSuccess) return <Flex fw fh><Spinner /></Flex>
  return (
    <Flex fw fh js>
      <Card style={{ marginBottom: 20, width: '100%', minHeight: '100vh' }}>
        <Flex fw fh>
          <Flex fw row>
            <Text value='Pianificazione Invio' upCase bold center title size={25} style={{ width: '50%', marginLeft: '25%' }} />
            <Flex row je style={{ width: '23%' }}>
              <Text value={`Pianificazione ${isActive ? 'attiva' : 'non attiva'}`} bold style={{ marginRight: 10 }} />
              <InputSwitch checked={isActive} onChange={({ value }) => setActiveSchedule(value)} />
            </Flex>
          </Flex>
          <Flex fw row style={{ marginTop: 30, marginBottom: 10 }}>
            <Input value={name} onChange={({ name }) => setSchedule('name', name)} label='Nome' id='name' style={{ marginRight: 20 }} />
            <Input dropdown value={reportType} onChange={({ reportType }) => setSchedule('reportType', reportType)} label='Tipo Report' id='reportType' options={reportTypes} />
          </Flex>
          <Input value={description} onChange={({ description }) => setSchedule('description', description)} id='description' label='Descrizione' style={{ marginTop: 10, marginBottom: 10 }} />
          <hr style={{ width: '100%', margin: '20px 0px', border: '1px solid black' }} />
          {reportType === 'business'
            ? <ClientList clientsList={[clientsList, (clientsList) => setSchedule('clientsList', clientsList)]} />
            : <ShipOwnerList
                shipOwnerClient={[shipOwnerClient, (shipOwnerClient) => setSchedule('shipOwnerClient', shipOwnerClient)]}
                shipOwnersList={[shipOwnersList, (shipOwnersList) => setSchedule('shipOwnersList', shipOwnersList)]}
              />}
          <hr style={{ width: '100%', margin: '20px 0px', border: '1px solid black' }} />
          <CreateCron day={[dayInMonth, (day) => setSchedule('dayInMonth', day)]} />
          <Flex fw row style={{ marginTop: 20 }}>
            <Button label='Salva' icon='check' style={{ marginRight: 10 }} onClick={save} />
            <Button label='Indetro' icon='back' />
          </Flex>
        </Flex>
      </Card>
    </Flex>
  )
}
