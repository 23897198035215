import BackgroundTheme from '../../Resources/BackgroundTheme.png'

export const Background = ({ children }) => (
  <div style={{
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    backgroundPositionY: 10,
    backgroundImage: `url(${BackgroundTheme})`,
    backgroundSize: 'cover'
  }}
  >
    {children}
  </div>
)
