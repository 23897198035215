import React from 'react'
import { Button, Flex, Spinner } from '../../Components'
import { useCQuery } from '../../Services'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useNavigate } from 'react-router-dom'
import { columnProps } from '../Cron/Functions'

export function TabUsers () {
  const { data: schedules = [], isSuccess } = useCQuery(['users'])
  const navigate = useNavigate()

  const BtnActions = ({ _id }) => (
    <Flex width={160} row>
      <Button round tooltip='Modifica' icon='edit' onClick={() => navigate('/users/' + _id)} />
    </Flex>
  )

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw fh>
      <Flex fw row je ae>
        <Button label='Crea Nuovo' icon='plus' style={{ marginRight: 10 }} onClick={() => navigate('/users/new')} />
      </Flex>
      <DataTable
        style={{ width: '100%' }}
        value={schedules} emptyMessage='Nessun utente' responsiveLayout='scroll' paginator rows={10} className='TableSignatures'
        filterDisplay='row' sortField='createdAt' sortOrder={-1}
      >
        <Column field='username' {...columnProps('Username')} />
        <Column style={{ border: 'none', width: 140, height: 15, textAlign: 'center' }} body={(props) => <BtnActions {...props} />} />
      </DataTable>
    </Flex>
  )
}
