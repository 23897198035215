export const searchMap = {
  mastroCliente: 'Mastro Cliente',
  codCliente12: 'Codice Cliente',
  codNazione: 'Codice Nazione',
  codRegione: 'Codice Regione',
  codZona: 'Codice Zona',
  email: 'Email',
  localita: 'Località',
  numeroTel: 'Numero Telefono',
  ragioneSociale: 'Ragione Sociale',
  codiceFiscale: 'Codice Fiscale',
  partitaIva: 'Partita Iva'
}

export const gridTemplate = `
'ragioneSociale ragioneSociale ragioneSociale ragioneSociale ragioneSociale ragioneSociale partitaIva partitaIva partitaIva partitaIva partitaIva partitaIva'
'codNazione codNazione codNazione codRegione codRegione codRegione codZona codZona codZona localita localita localita'
'email email email email numeroTel numeroTel numeroTel numeroTel codiceFiscale codiceFiscale codiceFiscale codiceFiscale'
`
export const baseUrlPdfPrinter = window?._env_?.ENVIRONMENT === 'production'
  ? 'https://apptourpdf.siapcn.it'
  : window?._env_?.ENVIRONMENT === 'staging'
    ? 'https://apptourpdfstaging.siap.cloud'
    : 'http://localhost:5051'

export const getOrigin = () => {
  try {
    const { origin } = window.location
    return origin
  } catch (e) { }
  return 'http://localhost:4000'
}
