import { useEffect, useState } from 'react'
import { FC } from '../Services'
import { Flex } from './Common/Flex'
import { Text } from './Common/Text'
import { Input } from './Common/Input'
import { Button } from './Common/Button'

export const SearchShipOwner = ({ codCliente, onSelectShipOwner }) => {
  const [selectedArmatore, setSelectedArmatore] = useState(null)
  const [options = [], setOptions] = useState([])

  useEffect(() => {
    const loadShipOwners = async () => {
      const res = await FC.service('biDB').get('searchCustomFieldsByClient', { query: { codCliente12: codCliente } })
      if (!res?.ok) return
      setOptions((res?.data?.campo4 || []).map(({ codice = '', descrizione = '', email = '' }) => ({ label: `${codice} - ${descrizione} - ${email}`, value: { codice, descrizione, email } })))
    }
    loadShipOwners()
  }, [codCliente])

  return (
    <Flex fw>
      <Text value="Seleziona l'armatore:" style={{ margin: '10px 0px' }} />
      <Flex row>
        <Input
          dropdown placeholder='Cerca Armatore' options={options} value={selectedArmatore}
          onChange={({ armatore }) => setSelectedArmatore(armatore)} id='armatore' style={{ marginRight: 20 }}
        />
        <Button
          disabled={!selectedArmatore} label='Scegli armatore' icon='check' onClick={() => {
            onSelectShipOwner(selectedArmatore)
            setSelectedArmatore(null)
          }}
        />
      </Flex>
    </Flex>
  )
}
