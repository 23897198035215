import React, { useState } from 'react'
import { Button, Flex, Input, Text } from '../../Components'
import { reportTypes } from '../Cron/Functions'
import { Client } from './Client'
import { ShipOwner } from './ShipOwner'
import { SelectMonthYear } from '../../Components/SearchClient/SelectMonthYear'
import { baseUrlPdfPrinter, getOrigin } from '../../Components/SearchClient/Functions'
import { LocalStorage } from '../../Services'

export function Preview () {
  const [reportType, setReportType] = useState('business')
  const [client, setClient] = useState('')
  const [shipOwner, setShipOwner] = useState('')
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth())
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

  const handlePreview = () => {
    const { accessToken } = LocalStorage.get('user')
    const url = reportType === 'business'
      ? `${baseUrlPdfPrinter}/reportBI?jwt=${accessToken}&clientCode=${client.codCliente12}&origin=${getOrigin()}&month=${selectedMonth}&year=${selectedYear}&reportType=${reportType}`
      : `${baseUrlPdfPrinter}/reportBI?jwt=${accessToken}&clientCode=${client.codCliente12}&origin=${getOrigin()}&month=${selectedMonth}&year=${selectedYear}&reportType=${reportType}&shipOwnerCode=${encodeURIComponent(shipOwner.codice)}`
    window.open(url, '_blank')
  }

  return (
    <Flex fw>
      <Text value='Anteprima Report' upCase bold center title size={25} />
      <Flex fw>
        <Input
          dropdown value={reportType} onChange={({ reportType }) => setReportType(reportType)}
          label='Tipo Report' id='reportType' options={reportTypes} style={{ width: 300, marginTop: 20 }}
        />
        {reportType === 'business'
          ? <Client client={[client, (client) => setClient(client)]} />
          : <ShipOwner
              shipOwnerClient={[client, (shipOwnerClient) => setClient(shipOwnerClient)]}
              shipOwner={[shipOwner, (shipOwner) => setShipOwner(shipOwner)]}
            />}
        <Flex>
          <Flex style={{ margin: 10 }}>
            <Text value={'Seleziona anno e mese per l\'anterpima'} title bold />
          </Flex>
          <Flex>
            <SelectMonthYear month={[selectedMonth, setSelectedMonth]} year={[selectedYear, setSelectedYear]} />
            <Button
              disabled={(reportType === 'business' && !client.codCliente12) || (reportType === 'marine' && (!shipOwner.codice || !client.codCliente12))}
              style={{ marginTop: 10 }} label='Visualizza Anteprima Report' icon='pattern' onClick={handlePreview}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
