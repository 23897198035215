import { useState } from 'react'
import { Button, Flex, Modal, Text } from '../../Components'
import { SearchClient } from '../../Components/SearchClient'

export const Client = ({ client: [client, setClient] }) => {
  const [modalAddVisible, setModalAddVisible] = useState(false)

  const onSelectClient = (client) => setClient(client)

  return (
    <Flex fw row style={{ marginTop: 20 }}>
      <Modal
        header='Ricerca e selezione cliente' noDismissable hideClose visible={modalAddVisible}
        onHide={() => setModalAddVisible(false)} style={{ width: '80%', maxHeight: '80%' }}
      >
        <Flex fw fh style={{ backgroundColor: 'rgba(231,231,231)', padding: 20, overflow: 'auto', borderRadius: 20 }}>
          <SearchClient onSelectClient={onSelectClient} hideModal={() => setModalAddVisible(false)} />
        </Flex>
      </Modal>
      <Text value='Cliente Selezionato:' style={{ marginRight: 20 }} />
      <Text value={client.codCliente12 ? `${client.codCliente12} - ${client.ragioneSociale}` : 'Nessun cliente selezionato'} style={{ marginRight: 20 }} />
      <Button label='Seleziona Cliente' icon='edit' onClick={() => setModalAddVisible(true)} />
    </Flex>
  )
}
