import { useState } from 'react'
import { Button, Flex, Modal, Text } from '../../Components'
import { SearchClient } from '../../Components/SearchClient'
import { ShipOwnerTable } from './ClientsAndShipTable'
import { SearchShipOwner } from '../../Components/SearchShipOwner'
import { ClientBox } from '../../Components/SearchClient/ClientBox'

export const ShipOwnerList = ({
  shipOwnersList: [shipOwnersList, setShipOwnersList],
  shipOwnerClient: [shipOwnerClient, setShipOwnerClient]
}) => {
  const [modalAddVisible, setModalAddVisible] = useState(false)

  const selectShipOwner = (shipOwner) => {
    const shipOwnerAlreadyInList = shipOwnersList.find((c) => c.codice === shipOwner.codice)
    if (shipOwnerAlreadyInList) return window.growl.show({ severity: 'error', summary: 'Attenzione', detail: "L'armatore è già presente nella lista" })
    setShipOwnersList([...shipOwnersList, shipOwner])
  }

  return (
    <Flex fw>
      <Modal
        header='Ricerca e selezione cliente ed armatore'
        noDismissable hideClose visible={modalAddVisible} onHide={() => setModalAddVisible(false)} style={{ width: '80%', maxHeight: '80%' }}
      >
        <Flex fw fh style={{ backgroundColor: 'rgba(231,231,231)', padding: 20, overflow: 'auto', borderRadius: 20 }}>
          {shipOwnerClient
            ? (
              <Flex fw>
                <ClientBox client={shipOwnerClient} isSelected />
                <Button icon='bin' label='Rimuovi selezione' onClick={() => setShipOwnerClient(null)} />
                <SearchShipOwner codCliente={shipOwnerClient?.codCliente12} onSelectShipOwner={selectShipOwner} />
              </Flex>)
            : <SearchClient onSelectClient={setShipOwnerClient} marine hideModal={() => {}} />}
        </Flex>
      </Modal>
      <Flex fw row style={{ marginTop: 20 }}>
        <Flex style={{ marginLeft: '25%', width: '50%' }}>
          <Text value='Clienti' bold title size={22} />
        </Flex>
        <Flex ae style={{ width: '25%' }}>
          <Button label='Aggiungi' icon='plus' onClick={() => setModalAddVisible(true)} />
        </Flex>
      </Flex>
      <ShipOwnerTable codCliente12={shipOwnerClient?.codCliente12} shipOwnersList={[shipOwnersList, setShipOwnersList]} />
    </Flex>
  )
}
