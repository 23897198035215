import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { columnProps } from './Functions'
import { useState } from 'react'
import { Button, Flex, Modal, Text } from '../../Components'
import { SelectMonthYear } from '../../Components/SearchClient/SelectMonthYear'
import { LocalStorage } from '../../Services'
import { baseUrlPdfPrinter, getOrigin } from '../../Components/SearchClient/Functions'

const BtnActions = ({ codCliente12, codice, reportType = 'business', list, updateList }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [modalPreviewVisible, setModalPreviewVisible] = useState(false)

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth())
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

  const handlePreview = () => {
    setModalPreviewVisible(false)
    const { accessToken } = LocalStorage.get('user')
    const url = reportType === 'business'
      ? `${baseUrlPdfPrinter}/reportBI?jwt=${accessToken}&clientCode=${codCliente12}&origin=${getOrigin()}&month=${selectedMonth}&year=${selectedYear}&reportType=${reportType}`
      : `${baseUrlPdfPrinter}/reportBI?jwt=${accessToken}&clientCode=${codCliente12}&origin=${getOrigin()}&month=${selectedMonth}&year=${selectedYear}&reportType=${reportType}&shipOwnerCode=${encodeURIComponent(codice)}`
    window.open(url, '_blank')
  }

  const onConfirmRemove = () => {
    reportType === 'business' && updateList(list.filter((client) => client.codCliente12 !== codCliente12))
    reportType === 'marine' && updateList(list.filter((shipOwner) => shipOwner.codice !== codice))
  }
  return (
    <Flex width={280} row>
      <Modal visible={modalVisible} header='Rimozione cliente' noDismissable hideClose onHide={() => setModalVisible(false)}>
        <Flex fw fh style={{ padding: 20 }}>
          <Text value='Vuoi rimuovere questo cliente?' />
          <Flex fw row jb style={{ marginTop: 20 }}>
            <Button label='Annulla' icon='bin' onClick={() => setModalVisible(false)} />
            <Button label='Conferma' icon='check' onClick={onConfirmRemove} />
          </Flex>
        </Flex>
      </Modal>
      <Modal visible={modalPreviewVisible} header='Anteprima report' noDismissable hideClose onHide={() => setModalPreviewVisible(false)}>
        <Flex fw fh style={{ padding: 20 }}>
          <Flex style={{ marginBottom: 10 }}>
            <Text value={'Seleziona anno e mese per l\'anterpima'} title bold />
          </Flex>
          <Flex js as fw>
            <SelectMonthYear month={[selectedMonth, setSelectedMonth]} year={[selectedYear, setSelectedYear]} />
            <Button style={{ marginTop: 10 }} label='Visualizza Anteprima Report' icon='pattern' onClick={handlePreview} />
          </Flex>
        </Flex>
      </Modal>
      <Button label='Rimuovi' icon='bin' onClick={() => setModalVisible(true)} />
      <Button label='Anteprima' icon='pdf' onClick={() => setModalPreviewVisible(true)} style={{ marginLeft: 5 }} />
    </Flex>
  )
}

export const ClientsTable = ({ clientsList: [clientsList, setClientsList] }) => {
  const getEditor = ({ value, editorCallback, rowData: { codCliente12 } }) => (
    <InputText
      type='text' value={value} onChange={({ target: { value } }) => {
        const newClientsList = [...clientsList]
        newClientsList[clientsList.findIndex((client) => client.codCliente12 === codCliente12)].email = value
        setClientsList(newClientsList)
        editorCallback(value)
      }}
    />)

  return (
    <DataTable
      style={{ width: '100%' }} filterDisplay='row' sortField='createdAt' sortOrder={-1} className='TableSignatures'
      value={clientsList} emptyMessage='Nessun invio pianficato' responsiveLayout='scroll' paginator rows={10}
    >
      <Column field='codCliente12' {...columnProps('Codice')} />
      <Column field='nome' {...columnProps('Nome')} />
      <Column field='cognome' {...columnProps('Cognome')} />
      <Column field='ragioneSociale' {...columnProps('Ragione sociale')} />
      <Column field='email' editable {...columnProps('Email')} editor={getEditor} />
      <Column body={props => <BtnActions {...props} reportType='business' list={clientsList} updateList={setClientsList} />} />
    </DataTable>
  )
}

export const ShipOwnerTable = ({ codCliente12, shipOwnersList: [shipOwnersList, setShipOwnersList] }) => {
  const getEditor = ({ value, editorCallback, rowData: { codice } }) => (
    <InputText
      type='text' value={value} onChange={({ target: { value } }) => {
        const newShipOwnersList = [...shipOwnersList]
        newShipOwnersList[shipOwnersList.findIndex((client) => client.codice === codice)].email = value
        setShipOwnersList(newShipOwnersList)
        editorCallback(value)
      }}
    />)

  return (
    <DataTable
      style={{ width: '100%' }} filterDisplay='row' sortField='createdAt' sortOrder={-1} className='TableSignatures'
      value={shipOwnersList} emptyMessage='Nessun armatore' responsiveLayout='scroll' paginator rows={10}
    >
      <Column field='codice' {...columnProps('Codice')} />
      <Column field='descrizione' {...columnProps('Descrizione')} />
      <Column field='email' editable {...columnProps('Email')} editor={getEditor} />
      <Column body={props => <BtnActions {...props} codCliente12={codCliente12} reportType='marine' list={shipOwnersList} updateList={setShipOwnersList} />} />
    </DataTable>
  )
}
