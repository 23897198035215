import { useState } from 'react'
import { Button, Flex, Modal, Text } from '../../Components'
import { SearchClient } from '../../Components/SearchClient'
import { SearchShipOwner } from '../../Components/SearchShipOwner'
import { ClientBox } from '../../Components/SearchClient/ClientBox'

export const ShipOwner = ({
  shipOwner: [shipOwner, setShipOwner],
  shipOwnerClient: [shipOwnerClient, setShipOwnerClient]
}) => {
  const [modalAddVisible, setModalAddVisible] = useState(false)

  const selectShipOwner = (shipOwner) => {
    setShipOwner(shipOwner)
    setModalAddVisible(false)
  }

  return (
    <>
      <Modal
        header='Ricerca e selezione cliente ed armatore'
        noDismissable hideClose visible={modalAddVisible} onHide={() => setModalAddVisible(false)} style={{ width: '80%', maxHeight: '80%' }}
      >
        <Flex fw fh style={{ backgroundColor: 'rgba(231,231,231)', padding: 20, overflow: 'auto', borderRadius: 20 }}>
          {shipOwnerClient
            ? (
              <Flex fw>
                <ClientBox client={shipOwnerClient} isSelected />
                <Button icon='bin' label='Rimuovi selezione' onClick={() => setShipOwnerClient(null)} />
                <SearchShipOwner codCliente={shipOwnerClient?.codCliente12} onSelectShipOwner={selectShipOwner} />
              </Flex>)
            : <SearchClient onSelectClient={setShipOwnerClient} marine hideModal={() => {}} />}
        </Flex>
      </Modal>
      <Flex fw row style={{ marginTop: 20 }}>
        <Text value='Cliente Selezionato:' style={{ marginRight: 20 }} />
        <Text value={shipOwnerClient?.codCliente12 ? `${shipOwnerClient.codCliente12} - ${shipOwnerClient.ragioneSociale}` : 'Nessun cliente selezionato'} style={{ marginRight: 20 }} />
      </Flex>
      <Flex fw row style={{ marginTop: 20 }}>
        <Text value='Armatore Selezionato:' style={{ marginRight: 20 }} />
        <Text value={shipOwner?.codice ? `${shipOwner.codice} - ${shipOwner.descrizione}` : 'Nessun armatore selezionato'} style={{ marginRight: 20 }} />
        <Button label='Seleziona Armatore' icon='edit' onClick={() => setModalAddVisible(true)} />
      </Flex>
    </>
  )
}
