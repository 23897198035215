import React, { useEffect, useState } from 'react'
import { Button, Card, Flex, Input, Text } from '../../Components'
import { FC, invalidateQuery, useCQuery } from '../../Services'
import { useNavigate, useParams } from 'react-router-dom'

export function User () {
  const { userId } = useParams()
  const { data: prompt, isSuccess } = useCQuery(['user', userId])
  const [username, setUsername] = useState('')
  const [society, setSociety] = useState('')
  const [codSociety, setCodSociety] = useState('')
  const [password, setPassword] = useState('')
  const [userWS, setUserWS] = useState('')
  const [passwordWS, setPasswordWS] = useState('')

  const navigate = useNavigate()
  const isNew = userId === 'new'

  const savePrompt = async () => {
    if (!username || !society || !codSociety || !password || !userWS || !passwordWS) {
      return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Compilare tutti i campi' })
    }
    let result
    (isNew)
      ? result = await FC.service('users').create({ username, society, codSociety, password, loginWS: { userWS, passwordWS } })
      : result = await FC.service('users').patch(userId, { username, society, codSociety, password, loginWS: { userWS, passwordWS } })
    if (result) {
      window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Prompt creato' })
      navigate('/users/' + result._id)
    }
    return invalidateQuery(['users', 'user'])
  }

  useEffect(() => {
    if (isSuccess) {
      const { username = '', society = '', codSociety = '', password = '', loginWS: { userWS = '', passwordWS = '' } = {} } = prompt || {}
      setUsername(username)
      setSociety(society)
      setCodSociety(codSociety)
      setPassword(password)
      setUserWS(userWS)
      setPasswordWS(passwordWS)
    }
  }, [isSuccess])

  return (
    <Flex fw js>
      <Card style={{ width: '90%' }}>
        <Flex fw>
          <Text value='Creazione Utente' size={30} bold title style={{ marginBottom: 20 }} />
          <Flex fw row wrap>
            <Input value={username} id='username' label='Username' style={style} onChange={({ username }) => setUsername(username)} />
            <Input value={society} id='society' label='Società' style={style} onChange={({ society }) => setSociety(society)} />
            <Input value={codSociety} id='codSociety' label='Codice Società' style={style} onChange={({ codSociety }) => setCodSociety(codSociety)} />
            <Input value={password} id='password' label='Password' style={style} onChange={({ password }) => setPassword(password)} />
            <Input value={userWS} id='userWS' label='Utente WS' style={style} onChange={({ userWS }) => setUserWS(userWS)} />
            <Input value={passwordWS} id='passwordWS' label='Password WS' style={style} onChange={({ passwordWS }) => setPasswordWS(passwordWS)} />
          </Flex>
          <Button style={{ width: 150 }} label={isNew ? 'Crea' : 'Salva'} icon='check' onClick={savePrompt} />
        </Flex>
      </Card>
    </Flex>
  )
}

const style = { width: '46%', marginBottom: '2%', marginLeft: '2%', marginRight: '2%' }
