import React from 'react'
import { getUrlParams } from '../../Services/Functions'
import { Spinner } from '../../Components'
import { pagesBusiness } from './Business'
import { pagesMarine } from './Marine'

const [accessToken, reportType] = getUrlParams(['jwt', 'reportType'])

export const ReportTemplate = () => {
  if (!accessToken) return <Spinner />
  const pages = reportType === 'business' ? pagesBusiness : pagesMarine
  return (
    <>
      {pages.map((page, index) => (
        page
      ))}
    </>
  )
}
