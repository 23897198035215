import React, { useEffect, useState } from 'react'
import { ChartTitle, Flex, Spinner } from '../../Components'
import { Api, formatPrice, getQueryParams } from '../Functions'

const headerKeys = ['O/D', 'Amount', 'Pax nr.', 'Avg. Price']
const keys = ['Origine_Destinazione', 'Costo_totale', 'N_pax_totali', 'Costo_medio_per_pax']

export const YourPreferredTop10RoutesYTD = () => {
  const [data, setData] = useState()
  const [isSuccess, setIsSuccess] = useState(false)

  const loadTop10RoutesYTD = async () => {
    const { data: { data } } = await Api().get('biDB/getTop10RoutesYTD', { params: { ...getQueryParams() } })
    setData(data)
    setIsSuccess(true)
  }

  useEffect(() => { loadTop10RoutesYTD() }, [])

  if (!isSuccess) return <Spinner />
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Flex fw fh>
        <ChartTitle
          title='Your preferred Top 10 Routes YTD'
          subTitle='A list of the most chosen routes for your company, reporting the total YTD cost and the average ticket price associated.'
        />
        <Flex fw row>
          <ProductTable data={data} />
        </Flex>
      </Flex>
    </div>
  )
}

const ProductTable = ({ data }) => {
  const Top10TotalAmount = data.reduce((acc, item, index) => index < (data?.length - 1) ? acc + parseFloat(item.Costo_totale) : acc, 0)
  const OtherTotalAmount = (parseFloat(data[data.length - 1].CostoTotale) - Top10TotalAmount)

  const Top10TotalPax = data.reduce((acc, item, index) => index < (data?.length - 1) ? acc + parseFloat(item.N_pax_totali) : acc, 0)
  const OtherTotalPax = (parseInt(data[data.length - 1].TotalePax) - Top10TotalPax)

  return (
    <>
      <table>
        {!!headerKeys?.length && (
          <thead>
            <tr>
              <td colSpan='2' style={{ border: '0px' }} />
              <td colSpan='3' style={{ backgroundColor: 'silver', color: '#FFF' }}>Year to date figures</td>
            </tr>
            <tr>
              <td>#</td>
              {headerKeys.map((key) => (
                <td className='firstRow' key={key}>{key}</td>
              ))}
            </tr>
          </thead>)}
        <tbody>
          {data.map((item, index) => index < (data?.length - 1) && (
            <tr key={index}>
              <td>{index + 1}</td>
              {keys.map((key, keyN) =>
                <td key={key}>
                  {keyN === 1 || keyN === 3 ? formatPrice(item[key]) : item[key]}
                </td>)}
            </tr>
          ))}
          <tr>
            <td />
            <td> OTHERS </td>
            <td> {formatPrice(OtherTotalAmount)} </td>
            <td> {OtherTotalPax || 0} </td>
            <td> {formatPrice(OtherTotalAmount && OtherTotalPax ? (OtherTotalAmount / OtherTotalPax) : 0)} </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
