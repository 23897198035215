import React, { useEffect, useState } from 'react'
import { ChartTitle, Flex, Spinner /*, Table */ } from '../../Components'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { Api /*, chunkArray */, formatPrice, getQueryParams } from '../Functions'

/**
 * Tutta la parte commentata veniva utilizzata per generare la tabella e poi il grafico.
 * Ora si visualizza solo il grafico.
 */
const colors = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF', '#800000', '#008000', '#000080', '#808000', '#800080', '#008080', '#C71585', '#708090', '#FF7F50', '#FFD700', '#32CD32', '#00FA9A', '#00BFFF', '#FF1493', '#1E90FF', '#DA70D6', '#FF69B4', '#7CFC00', '#4169E1', '#FFA07A', '#8FBC8F', '#00CED1', '#DDA0DD', '#6B8E23', '#FF6347', '#20B2AA', '#FA8072', '#2E8B57', '#7B68EE', '#FFFFF0', '#F0E68C', '#E6E6FA', '#D8BFD8', '#B0C4DE']
ChartJS.register(ArcElement, Tooltip, Legend)

const options = { plugins: { title: { display: false }, legend: { position: 'bottom', align: 'center', display: true } } }

export const RepartitionAmongVessels = () => {
  const [chartData, setChartData] = useState()
  const [isSuccess, setIsSuccess] = useState(false)
  // const [tableData, setTableData] = useState()
  // const [size, setSize] = useState(0)

  const loadRepartitionAmongVesselss = async () => {
    const { data: { data } } = await Api().get('biDB/getExpensesPerVesselYTD', { params: { ...getQueryParams() } })
    // setSize(data?.length)

    const mappedData = data.map((x) => ({ CentroCosto: x.Centro_Costo, CostoTotale: formatPrice(x.SpeseCorrente), percentage: parseFloat(x.percentage).toFixed(2) + ' %' }))
    // const chunkedElements = chunkArray(mappedData, 24)
    // setTableData(chunkedElements)

    const firstTen = mappedData.sort((a, b) => b.percentage - a.percentage).slice(0, 10)
    const othersPercentage = (100 - parseFloat(firstTen.reduce((acc, d) => acc + parseFloat(d.percentage), 0))).toFixed(2) + ' %'
    const others = {
      percentage: othersPercentage,
      CentroCosto: 'OTHERS'
    }

    if (data?.length > 10) firstTen.push(others)

    setChartData({
      labels: firstTen.map((x) => x.CentroCosto),
      datasets: [{ label: 'Repartition among cost centers', data: firstTen.map((x) => parseFloat(x.percentage)), backgroundColor: colors }]
    })
    setIsSuccess(true)
  }

  useEffect(() => { loadRepartitionAmongVesselss() }, [])

  if (!isSuccess || !chartData) return <Spinner />
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Flex fw fh style={{ border: '1px solid black' }}>
        <ChartTitle
          title='YTD repartition among Vessels'
          subTitle='Here below is presented a chart showing the repartition of travel cost among different vessels of your fleet.'
        />
        <Doughnut data={chartData} options={options} />
      </Flex>
    </div>
  )
  // if (size <= 10) return <SinglePage tablesData={tableData} chartData={chartData} />
  // else return <MultiPage tablesData={tableData} chartData={chartData} />
}

/*
const ProductTable = ({ data }) => {
  return <Table data={data} keys={['CentroCosto', 'CostoTotale']} headerKeys={['Vessel', 'Amount']} />
}

const SinglePage = ({ tablesData, chartData }) => {
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Flex fh fw>
        <ChartTitle
          title='YTD repartition among Vessels'
          subTitle='Here below is presented a chart showing the repartition of travel cost among different vessels of your fleet.'
        />
        <Flex fw row as>
          {tablesData?.map((table, i) => {
            return (
              <div key={i} style={{ marginLeft: 5, marginRight: 5 }}>
                <ProductTable data={table} />
              </div>
            )
          })}
        </Flex>
        <Flex style={{ width: '80%', marginTop: 10 }}>
          <Doughnut data={chartData} options={options} />
        </Flex>
      </Flex>
    </div>
  )
} */

/**
 * Creo 2 pagine.
 * Nella prima pagina viene visualizzata la tabella.
 * Nella seconda pagina il grafico.
 */
/*
const MultiPage = ({ tablesData, chartData }) => {
  const pages = ['page1', 'page2']
  return pages.map((e, i) => {
    return (
      <div key={i} style={{ width: '100vw', height: '100vh' }}>
        {i !== (pages.length - 1) &&
          <Flex fh fw>
            <ChartTitle
              title='YTD repartition among Vessels'
              subTitle='Here below is presented a chart showing the repartition of travel cost among different vessels of your fleet.'
            />
            <Flex fw row as>
              {tablesData?.map((table, i) => {
                return (
                  <div key={i} style={{ marginLeft: 5, marginRight: 5 }}>
                    <ProductTable data={table} />
                  </div>
                )
              })}
            </Flex>
          </Flex>}
        {i === (pages.length - 1) &&
          <Flex fw fh style={{ border: '1px solid black' }}>
            <Doughnut data={chartData} options={options} />
          </Flex>}
      </div>
    )
  })
}
*/
