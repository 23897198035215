import React, { useEffect, useState } from 'react'
import { ChartTitle, Flex, Spinner } from '../../Components'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { Api, formatPrice, getQueryParams } from '../Functions'

ChartJS.register(ArcElement, Tooltip, Legend)

const options = { responsive: true, plugins: { title: { display: false } } }

export const ProductMix = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [data, setData] = useState()
  const [chartData, setChartData] = useState()

  const loadProductMix = async () => {
    const { data: { data } } = await Api()('biDB/getProductMix', { params: { ...getQueryParams() } })
    const { AirTickets: YTDAirTickets = 0, HotelAccomodation: YTDHotelAccomodation = 0, LandTransfers: YTDLandTransfers = 0, OtherServices: YTDOtherServices = 0 } = data.reduce((acc, curr) => {
      acc.AirTickets += parseFloat(curr?.AirTickets || 0)
      acc.HotelAccomodation += parseFloat(curr?.HotelAccomodation || 0)
      acc.LandTransfers += parseFloat(curr?.LandTransfers || 0)
      acc.OtherServices += parseFloat(curr?.OtherServices || 0)
      return acc
    }, { AirTickets: 0, HotelAccomodation: 0, LandTransfers: 0, OtherServices: 0 })
    setChartData({
      labels: ['Air Tickets', 'Hotel Accomodation', 'Land Transfers', 'Other Services'],
      datasets: [{
        label: 'Product Mix',
        backgroundColor: ['#313fd6dd', '#313fd6aa', '#313fd688', '#313fd666'],
        data: [YTDAirTickets, YTDHotelAccomodation, YTDLandTransfers, YTDOtherServices]
      }]
    })
    setData(data)
    setIsSuccess(true)
  }

  useEffect(() => { loadProductMix() }, [])

  if (!isSuccess) return <Spinner />
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Flex fh fw>
        <ChartTitle title='Product Mix' subTitle='In this section in reported the allocation of total expenditure among different travel product categories.' />
        <Flex fw row style={{ width: '75%' }}>
          <ProductTable data={data} />
          <div style={{ width: 80 }} />
          <Pie data={chartData} options={options} />
        </Flex>
      </Flex>
    </div>
  )
}

const ProductTable = ({ data }) => {
  const { AirTickets: lastMonthAirTickets = 0, HotelAccomodation: lastMonthHotelAccomodation = 0, LandTransfers: lastMonthLandTransfers = 0, OtherServices: lastMonthOtherServices = 0 } = data[data?.length - 1] || {}
  const { AirTickets: YTDAirTickets = 0, HotelAccomodation: YTDHotelAccomodation = 0, LandTransfers: YTDLandTransfers = 0, OtherServices: YTDOtherServices = 0 } = data.reduce((acc, curr) => {
    acc.AirTickets += parseFloat(curr?.AirTickets || 0)
    acc.HotelAccomodation += parseFloat(curr?.HotelAccomodation || 0)
    acc.LandTransfers += parseFloat(curr?.LandTransfers || 0)
    acc.OtherServices += parseFloat(curr?.OtherServices || 0)
    return acc
  }, { AirTickets: 0, HotelAccomodation: 0, LandTransfers: 0, OtherServices: 0 })

  return (
    <table>
      <thead>
        <tr>
          <td />
          <td className='firstRow' style={{ backgroundColor: 'silver' }}>Monthly Amount</td>
          <td className='firstRow' style={{ backgroundColor: 'silver' }}>YTD Amount</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='firstCol'>Air Tickets</td>
          <td>{formatPrice(lastMonthAirTickets)}</td>
          <td>{formatPrice(YTDAirTickets)}</td>
        </tr>
        <tr>
          <td className='firstCol'>Hotel Accomodation</td>
          <td>{formatPrice(lastMonthHotelAccomodation)}</td>
          <td>{formatPrice(YTDHotelAccomodation)}</td>
        </tr>
        <tr>
          <td className='firstCol'>Land Transfers</td>
          <td>{formatPrice(lastMonthLandTransfers)}</td>
          <td>{formatPrice(YTDLandTransfers)}</td>
        </tr>
        <tr>
          <td className='firstCol'>Other Services</td>
          <td>{formatPrice(lastMonthOtherServices)}</td>
          <td>{formatPrice(YTDOtherServices)}</td>
        </tr>
        <tr>
          <td className='firstCol'>Total</td>
          <td>{formatPrice(parseFloat(lastMonthAirTickets) + parseFloat(lastMonthHotelAccomodation) + parseFloat(lastMonthLandTransfers) + parseFloat(lastMonthOtherServices))}</td>
          <td>{(formatPrice(YTDAirTickets + YTDHotelAccomodation + YTDLandTransfers + YTDOtherServices))}</td>
        </tr>
      </tbody>
    </table>
  )
}
