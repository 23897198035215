import React from 'react'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import PoweredBySIAP from './PoweredBySIAP.png'
import { HamburgerMenu, SlidingMenu } from './SlidingMenu'
import { Flex } from './Flex'
import { Text } from './Text'
import { useAuth } from '../../Provider/AuthProvider'
import { useMenuItems, usePageTitle } from '../../Services/MenuConfig'

export const TopMenu = () => {
  const { width } = useWindowSize()
  const { onLogout, user = {} } = useAuth()
  const { user: { society, username } = {} } = user || {}
  const title = usePageTitle()
  const navigate = useNavigate()
  const menuItems = useMenuItems(username)

  const menuAction = (page) => page === 'logout' ? onLogout() : navigate(page)

  return (
    <>
      <SlidingMenu menuAction={menuAction} menuItems={menuItems} />
      <motion.div style={styles.containerStyle} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
        <Flex js row fw>
          <HamburgerMenu />
          <Text title bold size='20px' style={{ marginLeft: 20 }} value={title} />
        </Flex>
        <Flex row fw je>
          {width > 1240 && <Text bold title size='20px' style={{ marginRight: 20, whiteSpace: 'nowrap' }} value={society || ''} />}
          <img src={PoweredBySIAP} style={{ maxHeight: 46, margin: 2, marginRight: 10 }} />
        </Flex>
      </motion.div>
    </>
  )
}
const styles = {
  containerStyle: {
    width: '100%',
    height: 50,
    zIndex: 50,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    boxShadow: '0px 2px 2px rgba(100, 100, 100, 0.5)',
    backdropFilter: 'blur(4px) saturate(180%)',
    WebkitBackdropFilter: 'blur(4px) saturate(180%)',
    y: -50,
    opacity: 0
  }
}
