import React, { useEffect, useState } from 'react'
import { Flex, Spinner, Text } from '../../Components'
import { Api } from '../Functions'
import { getUrlParams } from '../../Services/Functions'

const clientCode = getUrlParams('clientCode')

export function SeeYouSoon () {
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(false)

  const loadClientInfo = async () => {
    if (!clientCode) return
    setLoading(true)
    const { data: { user } } = await Api().post('authentication', { strategy: 'jwt', accessToken: getUrlParams('jwt') })
    setUser(user)
    setLoading(false)
  }

  useEffect(() => { loadClientInfo() }, [])

  if (loading) return <Spinner />
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Flex fw fh ae je style={{ backgroundColor: '#2a68b5' }}>
        <Flex style={{ width: '50%', height: '50%' }}>
          <Text value='See you soon,' color='#FFF' size={60} bold title style={{ marginBottom: 50 }} />
          <img
            src={clientCode === '20 1 2  1170'
              ? 'https://s3.eu-central-1.amazonaws.com/siap.apptour/COLUMBUS+TRAVEL+LOGO.PNG'
              : user?.logo}
            alt='logoCompany' style={{ width: '50%', height: 'auto' }}
          />
        </Flex>
      </Flex>
    </div>
  )
}
