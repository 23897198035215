import {
  AggregateFigures,
  ReportTitle,
  MontlhyExpensesPerVessel,
  YTDExpensesPerVessel,
  RepartitionAmongVessels,
  ProductMix,
  AggregateFiguresPerVesselType,
  AggregateFiguresPerRank,
  YourPreferredTop10RoutesThisMonth,
  YourPreferredTop10RoutesYTD,
  YourPreferredTop20Origins,
  YourPreferredTop20Destinations,
  Top10Airlines,
  AirTicketsMonthlyClusterAnalysis,
  CarbonFootprint,
  ThanksPage,
  SeeYouSoon
} from '../../Charts/Marine'

export const pagesMarine = [
  <ReportTitle key={1} />, // page with client name and logo
  <AggregateFigures key={2} />, // page 2 of the report
  <MontlhyExpensesPerVessel key={3} />, // page 3 of the report
  <YTDExpensesPerVessel key={4} />, // page 4 of the report
  <RepartitionAmongVessels key={5} />, // page 5 of the report
  <ProductMix key={6} />, // page 6 of the report
  <AggregateFiguresPerVesselType key={7} />, // page 7 of the report
  <AggregateFiguresPerRank key={8} />, // page 8 of the report
  <YourPreferredTop10RoutesThisMonth key={9} />, // page 9 of the report
  <YourPreferredTop10RoutesYTD key={10} />, // page 10 of the report
  <YourPreferredTop20Origins key={11} />, // page 11 of the report
  <YourPreferredTop20Destinations key={12} />, // page 12 of the report
  <Top10Airlines key={13} />, // page 13 of the report
  <AirTicketsMonthlyClusterAnalysis key={14} />, // page 14 of the report
  <CarbonFootprint key={15} />, // page 15 of the report
  <ThanksPage key={16} />, // page 16 of the report
  <SeeYouSoon key={17} /> // page 17 of the report
]
