import { useEffect, useState } from 'react'
import { FC, invalidateQuery, useCQuery } from '../../Services'

export const useSchedule = (scheduleId) => {
  const [schedule, setSchedule] = useState({})
  const { data, isSuccess } = useCQuery(['schedule', scheduleId])

  useEffect(() => {
    if (isSuccess) {
      // const { _id, name, description, clientsList, sentReports, reportType, isActive } = data
      /* setSchedule({
        ...schedule,
        ...(_id ? { _id } : {}),
        ...name ? { name } : {},
        ...description ? { description } : {},
        ...clientsList ? { clientsList } : {},
        ...sentReports ? { sentReports } : {},
        ...reportType ? { reportType } : {},
        ...isActive ? { isActive } : {}
      }) */
      setSchedule(data)
    }
  }, [isSuccess, data])

  const setScheduleProp = (prop, value) => {
    if (prop === 'reportType' && schedule?._id !== 'new') return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Non puoi modificare il tipo di report di una pianificazione esistente' })
    setSchedule({ ...schedule, [prop]: value })
  }

  return [schedule, setScheduleProp, isSuccess]
}

export const useScheduleField = (scheduleId, field) => {
  const [value, setValue] = useState('')
  const [schedule, setSchedule, isSuccess] = useSchedule(scheduleId)

  useEffect(() => { if (isSuccess) setValue(schedule[field]) }, [isSuccess])

  const setValueProp = (value) => {
    setValue(value)
    setSchedule(field, value)
  }

  return [value, setValueProp, isSuccess]
}

export const reportTypes = [{ label: 'Marine', value: 'marine' }, { label: 'Business', value: 'business' }]

export const checkError = ({ name, reportType, clientsList, dayInMonth, shipOwnersList }) => {
  if (!name) return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Inserisci un nome' })
  if (!reportType) return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Seleziona un tipo di report' })
  if (!clientsList.length && reportType === 'business') return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Seleziona almeno un cliente' })
  if (!shipOwnersList.length && reportType === 'marine') return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Seleziona almeno un armatore' })
  if (!dayInMonth) return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Seleziona un giorno' })
  return true
}

export const saveSchedule = async (schedule) => {
  if (!checkError(schedule)) return
  if (schedule._id === 'new') {
    delete schedule._id
    const res = await FC.service('schedules').create(schedule)
    invalidateQuery(['schedules', 'schedule'])
    window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
    return res
  }
  const res = await FC.service('schedules').patch(schedule._id, schedule)
  invalidateQuery(['schedules', 'schedule'])
  window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
  return res
}

export const columnProps = (filterPlaceholder) => ({
  header: filterPlaceholder,
  headerStyle: { visibility: 'hidden' },
  filter: true,
  filterMatchMode: 'contains',
  filterPlaceholder,
  style: { textAlign: 'center', border: 'none' },
  showFilterMenu: false,
  showClearButton: false
})

export const deleteSchedule = async (scheduleId) => {
  const res = await FC.service('schedules').patch(scheduleId, { deleted: true })
  invalidateQuery(['schedules', 'schedule'])
  window.growl.show({ severity: 'success', summary: 'Success', detail: 'Eliminazione effettuata' })
  return res
}
