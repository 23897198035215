import React from 'react'
import { Text, Flex } from '.'

export const ChartTitle = ({ title = '', subTitle = '', style }) => {
  return (
    <Flex style={{ padding: '0px 15%', ...style }} fw as>
      <Text value={title} color='#313fd6' bold size={30} />
      <Text size={20} style={{ marginTop: 10, marginBottom: 30 }} value={subTitle} />
    </Flex>
  )
}
