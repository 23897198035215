import React, { useEffect, useState } from 'react'
import { Flex, Spinner, Text } from '../../Components'
import BackgroundImg from './BackgroundFirstPage.png'
import { Api, getQueryParams } from '../../Charts/Functions'
import { getUrlParams } from '../../Services/Functions'

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const monthNumber = getUrlParams('month') || new Date().getMonth()
const year = getUrlParams('year') || new Date().getFullYear()
const clientCode = getUrlParams('clientCode')
const month = months[parseInt(monthNumber)]

export const ReportTitle = () => {
  // const [clientName, setClientName] = useState()
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)
  const [shipOwnerName, setShipOwnerName] = useState('')

  const loadClientInfo = async () => {
    if (!clientCode) return
    setLoading(true)
    // const { data: { data: [client] } } = await Api().get('biDB/searchClient', { params: { codCliente12: clientCode } })
    // setClientName(client?.datiGeneraliCliente?.ragioneSociale || client?.datiGeneraliCliente?.ragioneSocInRichiesta)
    const { data: { user } } = await Api().post('authentication', { strategy: 'jwt', accessToken: getUrlParams('jwt') })
    const { data: shipOwnerName } = await Api().get('biDB/shipOwnerName', { params: { ...getQueryParams() } })
    setShipOwnerName(shipOwnerName)
    setUser(user)
    setLoading(false)
  }

  useEffect(() => { loadClientInfo() }, [])

  if (loading) return <Spinner />

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Background>
        <Flex fw fh row>
          <Flex style={{ width: '50%' }}>
            <img
              src={clientCode === '20 1 2  1170'
                ? 'https://s3.eu-central-1.amazonaws.com/siap.apptour/COLUMBUS+TRAVEL+LOGO.PNG'
                : user?.logo}
              alt='logoCompany' style={{ width: '50%', height: 'auto' }}
            />
          </Flex>
          <Flex style={{ width: '50%', height: '70%' }} je>
            <div>
              <Text value={shipOwnerName} color='#2a489baa' bold size={'clientName'?.length > 23 ? 30 : 40} />
              <Text value='Monthly Travel Report' color='#2a489b' bold size={60} />
              <hr style={{ marginLeft: '50%', width: '50%', border: '1px solid #2a489b' }} />
              <Text value={`${month} ${year}`} color='#2a489b' style={{ width: '100%', textAlign: 'right' }} size={25} />
            </div>
          </Flex>
        </Flex>
      </Background>
    </div>

  )
}

export const Background = ({ children }) => (
  <div style={{
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    backgroundPosition: 'center',
    backgroundImage: `url(${BackgroundImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'noRepeat'
  }}
  >
    {children}
  </div>
)
