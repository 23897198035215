import { useLocation } from 'react-router-dom'

const pathMap = [
  { path: '/login', label: 'Login' },
  { path: '/users', label: 'Utenti' }
]

export const usePageTitle = () => {
  useLocation()
  if (window.location.pathname === '/') return 'Home'

  const matchTitle = ({ path }) => path === window.location.pathname

  return pathMap.find(matchTitle)?.label || 'Home'
}

export const useMenuItems = (username) => ([
  { label: 'Anteprima', icon: 'play', path: '/preview' },
  { label: 'Pianificazioni Invii', icon: 'notes', path: '/cron' },
  (username === 'admin' && { label: 'Crea Utenza', icon: 'add', path: '/users/list' }),
  { label: 'Logout', icon: 'primeicon pi-power-off', path: 'logout' }
].filter(Boolean))
