import React, { useEffect, useState } from 'react'
import { Flex, Spinner, Text } from '../../Components'
import img1 from '../../Resources/img1.png'
import img2 from '../../Resources/img2.png'
import { getUrlParams } from '../../Services/Functions'
import { Api } from '../../Charts/Functions'

const clientCode = getUrlParams('clientCode')

export function ThanksPage () {
  const [loading, setLoading] = useState(false)
  const [clientName, setClientName] = useState()
  const [user, setUser] = useState()

  const loadClientInfo = async () => {
    if (!clientCode) return
    setLoading(true)
    const { data: { data: [client] } } = await Api().get('biDB/searchClient', { params: { codCliente12: clientCode } })
    setClientName(client?.datiGeneraliCliente?.ragioneSociale || client?.datiGeneraliCliente?.ragioneSocInRichiesta)
    const { data: { user } } = await Api().post('authentication', { strategy: 'jwt', accessToken: getUrlParams('jwt') })
    setUser(user)
    setLoading(false)
  }

  useEffect(() => { loadClientInfo() }, [])

  if (loading) return <Spinner />
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Flex fw fh style={{ marginTop: 200 }}>
        <Flex style={{ width: '80%', height: '80%' }} row>
          <div style={{ display: 'flex', flexDirection: 'column', height: '98%', width: '30%', alignContent: 'flex-end' }}>
            <img src={img1} style={{ width: 'calc(100% - 20px)', height: 'calc(50% - 5px)', marginBottom: 12, marginRight: 20, objectFit: 'contain' }} />
            <img src={img2} style={{ width: 'calc(100% - 20px)', height: 'calc(50% - 5px)', marginTop: 12, marginRight: 20, objectFit: 'contain' }} />
          </div>
          <Flex style={{ width: '70%', backgroundColor: '#92a3e1', padding: 20 }} as fh>
            <Text value={`Thank you for choosing ${user?.society}!`} size={55} style={{ fontWeight: 900 }} title color='#021c78' /><br /><br />
            <Text color='#FFF' size={30} style={{ lineHeight: 1.2, textAlign: 'justify' }} value={`Dear ${clientName},`} /><br />
            <Text color='#FFF' size={30} style={{ lineHeight: 1.2, textAlign: 'justify' }} value={`Thank you for choosing ${user?.society} to handle the arrangements for your company’s crew upcoming trips. We truly appreciate your business and the trust you have placed in us.`} /><br />
            <Text color='#FFF' size={30} style={{ lineHeight: 1.2, textAlign: 'justify' }} value='Our team is committed to providing you with the highest level of service and ensuring that your trip is a success. We understand the importance of efficient and reliable travel arrangements, and we are dedicated to meeting and exceeding your expectations.' /><br />
            <Text color='#FFF' size={30} style={{ lineHeight: 1.2, textAlign: 'justify' }} value='Thank you again for choosing our travel agency, and we look forward to working with you on these and future trips.' /><br />
            <Text color='#FFF' size={30} style={{ lineHeight: 1.2, textAlign: 'justify' }} value='Best regards,' />
            <Text color='#FFF' size={30} style={{ lineHeight: 1.2, textAlign: 'justify' }} value={user?.society} />
          </Flex>
        </Flex>
      </Flex>
    </div>
  )
}
