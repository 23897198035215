import React, { useState } from 'react'
import { Button, Flex } from '..'
import { FC } from '../../Services'
import { ClientCodeSearch, ClientFieldSearch, SearchedClients } from './Components'

export function SearchClient ({ onSelectClient, hideModal, marine = false }) {
  const [isSearching, setIsSearching] = useState(false)
  const [clients, setClients] = useState([])
  const [selectedClient, setSelectedClient] = useState(null)
  const [showClientsResults, setShowClientsResults] = useState(false)
  const [searchType, setSearchType] = useState(null)

  const loadClients = async (querySearch) => {
    setIsSearching(true)
    const res = await FC.service('biDB').get('searchClient', { query: querySearch })
    setIsSearching(false)
    if (!res?.ok) return
    setClients((res?.data || []).map(({
      datiGeneraliCliente: { codCliente12, nome, cognome, ragioneSociale, localita, provincia, indirizzo, cap, email },
      datiContabiliCliente: { codiceFiscale }
    }) => ({ codCliente12, nome, cognome, ragioneSociale, localita, provincia, indirizzo, cap, email, codiceFiscale })))
    setShowClientsResults(true)
  }

  const loadClientsByCode = async (code) => {
    setIsSearching(true)
    const res = await FC.service('biDB').get('searchClient', { query: { codCliente12: code } })
    setIsSearching(false)
    if (!res?.ok) return
    setClients((res?.data || []).map(({
      datiGeneraliCliente: { codCliente12, nome, cognome, ragioneSociale, localita, provincia, indirizzo, cap, email },
      datiContabiliCliente: { codiceFiscale }
    }) => ({ codCliente12, nome, cognome, ragioneSociale, localita, provincia, indirizzo, cap, email, codiceFiscale })))
    setShowClientsResults(true)
  }

  const handleSelect = (client) => {
    setSelectedClient(client)
  }

  const selectClient = (client) => {
    onSelectClient(client)
    setSelectedClient(null)
    setClients([])
    setShowClientsResults(false)
    setSearchType(null)
    hideModal()
  }

  const onCleanUp = () => {
    setClients([])
    setIsSearching(false)
    setSelectedClient(null)
    setShowClientsResults(false)
  }

  const changeSearchType = (type) => () => {
    setClients([])
    setIsSearching(false)
    setSelectedClient(null)
    setShowClientsResults(false)
    setSearchType(type)
  }

  return (
    <Flex fw fh js>
      {!searchType && (
        <Flex fw fh row>
          <Button icon='lens' label='Ricerca per codice cliente' onClick={changeSearchType('clientCode')} style={{ marginLeft: 10, marginRight: 10 }} />
          <Button icon='lens' label='Ricerca per dati anagrafici cliente' onClick={changeSearchType('clientData')} style={{ marginLeft: 10, marginRight: 10 }} />
        </Flex>
      )}
      {searchType === 'clientCode' && <ClientCodeSearch isSearching={isSearching} searchClient={loadClientsByCode} onCleanUp={onCleanUp} changeSearchType={changeSearchType} />}
      {searchType === 'clientData' && <ClientFieldSearch isSearching={isSearching} searchClient={loadClients} onCleanUp={onCleanUp} changeSearchType={changeSearchType} />}
      <SearchedClients isSearching={isSearching} clients={clients} selectedClient={selectedClient} handleSelect={handleSelect} showClientsResults={showClientsResults} />
      {!!selectedClient && (
        <Flex fw style={{ padding: 20, border: '1px solid pink' }}>
          <Button label={marine ? 'Seleziona armatore' : 'Conferma'} icon='check' onClick={() => selectClient(selectedClient)} />
        </Flex>
      )}
    </Flex>
  )
}
