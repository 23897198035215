import React, { useState } from 'react'
import { Button, Flex, Modal, Spinner, Text } from '../../Components'
import { useCQuery } from '../../Services'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useNavigate } from 'react-router-dom'
import { columnProps, deleteSchedule } from './Functions'

export default function TabReports () {
  const { data: schedules = [], isSuccess } = useCQuery(['schedules'])
  const navigate = useNavigate()

  const BtnActions = ({ _id }) => {
    const [modalVisible, setModalVisible] = useState(false)
    return (
      <Flex width={160} row>
        <Modal visible={modalVisible} header='Cancellazione pianificazione' onHide={() => setModalVisible(false)}>
          <Flex fw>
            <Text value='Vuoi cancellare questa pianificazione?' />
            <Flex fw row jb style={{ marginTop: 20 }}>
              <Button label='Annulla' icon='bin' />
              <Button label='Conferma' icon='check' onClick={() => deleteSchedule(_id)} />
            </Flex>
          </Flex>
        </Modal>
        <Button round tooltip='Modifica' icon='edit' onClick={() => navigate('/cron/' + _id)} />
        <Button style={{ marginLeft: 20 }} round tooltip='Elimina' icon='bin' onClick={() => setModalVisible(true)} />
      </Flex>
    )
  }

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw fh>
      <Flex fw row je ae>
        <Button label='Crea Nuovo' icon='plus' style={{ marginRight: 10 }} onClick={() => navigate('/cron/new')} />
      </Flex>
      <DataTable
        style={{ width: '100%' }}
        value={schedules} emptyMessage='Nessun invio pianficato' responsiveLayout='scroll' paginator rows={10} className='TableSignatures'
        filterDisplay='row' sortField='createdAt' sortOrder={-1}
      >
        <Column field='name' {...columnProps('Nome')} />
        <Column field='reportType' {...columnProps('Tipo Report')} body={({ reportType }) => reportType.charAt(0).toUpperCase() + reportType.slice(1)} />
        <Column {...columnProps('Numero clienti')} body={(row) => row.reportType === 'business' ? row?.clientsList?.length : row?.shipOwnersList?.length} />
        <Column style={{ border: 'none', width: 140, height: 15, textAlign: 'center' }} body={(props) => <BtnActions {...props} />} />
      </DataTable>
    </Flex>
  )
}
