import React, { useEffect, useState } from 'react'
import { ChartTitle, Flex, Spinner } from '../../Components'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Api, formatPrice, getQueryParams, months } from '../Functions'

const options = { responsive: true, plugins: { title: { display: false } } }

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const AggregateFigures = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [data, setData] = useState()
  const [chartData, setChartData] = useState()

  const loadAggregateFigures = async () => {
    const { data: { data } } = await Api().get('biDB/getAggregateFigures', { params: { ...getQueryParams() } })
    setData(data)
    setChartData({
      labels: Object.keys(months).map((month) => months[month]),
      datasets: [{
        label: 'Costo Totale',
        data: Object.keys(months).map((month) => data.find(({ Mese_Descrizione: meseDesc }) => meseDesc === month)?.Costo_Totale),
        backgroundColor: '#313fd6dd'
      }]
    })
    setIsSuccess(true)
  }

  useEffect(() => { loadAggregateFigures() }, [])

  if (!isSuccess) return <Spinner />
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Flex fw fh>
        <ChartTitle title='Aggregate figures' subTitle='In the following presentation you will find a description of issued travel services, statistics relating to services offer to your company and financial figures for the considered period.' />
        <Flex row>
          <Flex fw fh>
            <div style={{ margin: 50 }}>
              <MonthTable data={data} />
            </div>
            <div style={{ margin: 50 }}>
              <YTDTable data={data} />
            </div>
          </Flex>
          <Flex fw fh>
            <div style={{ margin: 50 }}>
              <AvgTable data={data} />
            </div>
          </Flex>
        </Flex>
        <Flex fw style={{ height: 400 }}>
          <Bar data={chartData} options={options} />
        </Flex>
      </Flex>
    </div>
  )
}

const MonthTable = ({ data, style }) => (
  <table>
    <tbody>
      <tr>
        <td className='firstCol'>Monthly Travel Expenses</td>
        <td>{formatPrice(data[data?.length - 1]?.Costo_Totale)}</td>
      </tr>
      <tr>
        <td className='firstCol'>Monthly nr. of pax</td>
        <td>{data[data?.length - 1]?.Numero_Totale_Passeggeri || 0}</td>
      </tr>
    </tbody>
  </table>
)

const YTDTable = ({ data }) => (
  <table>
    <tbody>
      <tr>
        <td className='firstCol'>YTD Travel Expenses</td>
        <td>{formatPrice(data.reduce((acc, { Costo_Totale: totalCost }) => acc + parseFloat(totalCost), 0))}</td>
      </tr>
      <tr>
        <td className='firstCol'>YTD nr. of pax</td>
        <td>{data.reduce((acc, { Numero_Totale_Passeggeri: totalPax }) => acc + parseFloat(totalPax), 0)}</td>
      </tr>
    </tbody>
  </table>
)

const AvgTable = ({ data }) => (
  <table>
    <tbody>
      <tr>
        <td className='firstCol' style={{ backgroundColor: 'silver', color: 'white' }}>Monthly avg. pax cost</td>
        <td>{formatPrice(data[data?.length - 1]?.Media_Spesa_Passeggero)}</td>
      </tr>
      <tr>
        <td className='firstCol' style={{ backgroundColor: 'silver', color: 'white' }}>YTD avg. pax cost</td>
        <td>{formatPrice(data.reduce((acc, { Media_Spesa_Passeggero: media }) => acc + parseFloat(media), 0).toFixed(2) / data?.length)}</td>
      </tr>
    </tbody>
  </table>
)
