import React, { useEffect, useState } from 'react'
import { ChartTitle, Flex, Spinner, Table } from '../../Components'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { Api, chunkArray, formatPrice, raggruppaArray } from '../Functions'
import { getUrlParams } from '../../Services/Functions'

const colors = ['#FFC300', '#FF5733', '#C70039', '#900C3F', '#581845', '#0047AB', '#008080', '#F0E68C', '#00FF7F', '#7FFF00', '#FF1493', '#8B008B', '#1E90FF', '#6A5ACD', '#FF4500', '#DAA520', '#FF69B4', '#48D1CC', '#9400D3', '#FF8C00']

ChartJS.register(ArcElement, Tooltip, Legend)

const options = { plugins: { title: { display: true }, legend: { position: 'bottom', align: 'center' } } }

export const RepartitionAmongCostCenter = () => {
  const [chartData, setChartData] = useState()
  const [isSuccess, setIsSuccess] = useState(false)
  const [tableData, setTableData] = useState()
  const [size, setSize] = useState(0)

  const loadRepartitionAmongCostCenters = async () => {
    const clientCode = getUrlParams('clientCode')
    const { data: { data } } = await Api()('biDB/getRepartitionAmongCostCenters', { params: { ...(clientCode ? { clientCode } : {}) } })
    setSize(data?.length)
    const mappedData = data.map((x) => ({ DescrizioneCentroDiCosto: x.DescrizioneCentroDiCosto, CentroCosto: x.CentroCosto, CostoTotale: formatPrice(x.CostoTotale) }))
    setTableData(mappedData)
    const firstTwenty = data.slice(0, 20)
    const othersAmount = (parseFloat(data.slice(20).reduce((acc, d) => acc + parseFloat(d.CostoTotale), 0))).toFixed(2)
    const others = [...firstTwenty, { DescrizioneCentroDiCosto: 'OTHERS', CostoTotale: parseFloat(othersAmount) }]
    setChartData({
      labels: others.map((x) => x.DescrizioneCentroDiCosto || x.CentroCosto),
      datasets: [{ label: 'Repartition among cost centers', data: others.map((x) => x.CostoTotale), backgroundColor: colors }]
    })
    setIsSuccess(true)
  }

  useEffect(() => { loadRepartitionAmongCostCenters() }, [])

  if (!isSuccess || !chartData) return <Spinner />
  if (size <= 20) return <SinglePage tableData={tableData} chartData={chartData} />
  return <MultiPage tableData={tableData} chartData={chartData} />
}

const MultiPage = ({ tableData, chartData }) => {
  const chunked = chunkArray(tableData, 20) // [ [], [], [], [] ]
  const groupedBy3 = raggruppaArray(chunked) // [ [ [], [], [] ], [ [], [], [] ]]

  groupedBy3.push({ chart: true })

  return groupedBy3?.map((page, i) => (
    <div key={i} style={{ width: '100vw', height: '100vh' }}>
      {i !== (groupedBy3.length - 1) && (
        <Flex fh fw>
          {i === 0 && <ChartTitle title='Repartition among cost centers' subTitle='Here below is presented a chart showing the repartition of travel cost among different cost centers.' />}
          <Flex fw row>
            {page?.map((element, i) => (
              <Flex key={i} style={{ marginLeft: 10, marginRight: 10 }}>
                <ProductTable data={element} />
              </Flex>
            ))}
          </Flex>
        </Flex>
      )}
      {i === (groupedBy3.length - 1) && (
        <Flex fw fh style={{ border: '1px solid black' }}>
          <Pie data={chartData} options={options} />
        </Flex>
      )}
    </div>
  )
  )
}

const SinglePage = ({ tableData, chartData }) => (
  <div style={{ width: '100vw', height: '100vh' }}>
    <Flex fh fw>
      <ChartTitle title='Repartition among cost centers' subTitle='Here below is presented a chart showing the repartition of travel cost among different cost centers. ' />
      <Flex style={{ width: '80%' }} row>
        <ProductTable data={tableData} />
        <div style={{ width: 80 }} />
        <Pie data={chartData} options={options} />
      </Flex>
    </Flex>
  </div>
)

const ProductTable = ({ data }) =>
  <Table data={data} keys={['CentroCosto', 'CostoTotale']} headerKeys={['Cost Center', 'Amount']} />
