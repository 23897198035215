import React, { useEffect, useState } from 'react'
import { Flex, Spinner, Text } from '../../Components'
import { Api, getQueryParams, months } from '../Functions'
import { getUrlParams } from '../../Services/Functions'
import { Line } from 'react-chartjs-2'
import { CategoryScale, Chart as ChartJS, Legend, LineElement, LinearScale, PointElement, Title, Tooltip } from 'chart.js'

const ranges = ['0€ - 499€', '500€ - 999€', '1.000€ - 1.499€', '1.500€ - 1.999€', '2.000€ - 2.499€', '2.500€ - 2.999€', '3.000€ - 3.999€', '4.000€ +']
const headerKeys = ['Month / Year', ...ranges, 'Total flights', 'Flights > 1.500€ in %']
const keys = ['Mese_Descrizione', ...ranges.map((i, n) => 'R' + (n + 1)), 'Total_Flights', 'Per']

const options = { responsive: true, plugins: { title: { display: false }, legend: { display: false } } }

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export const AirTicketsMonthlyClusterAnalysis = () => {
  const [data, setData] = useState()
  const [chartData, setChartData] = useState()
  const [isSuccess, setIsSuccess] = useState(false)

  const loadTop10RoutesByMonth = async () => {
    const { data: { data = [] } } = await Api().get('biDB/getAirTicketsMonthlyClusterAnalysis', { params: { ...getQueryParams() } })
    const lastMonthData = data[data.length - 1] || {}
    setChartData({
      labels: ranges,
      datasets: [{ label: 'Flights', data: ranges.map((i, n) => lastMonthData['R' + (n + 1)]), borderColor: 'rgba(255, 99, 132, 1)' }]
    })
    setData(data)
    setIsSuccess(true)
  }

  useEffect(() => { loadTop10RoutesByMonth() }, [])

  if (!isSuccess || !chartData) return <Spinner />
  if (!data?.length) return null
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Flex fw fh>
        <Text value='Air Tickets Monthly Cluster Analysis' color='#313fd6' bold size={30} style={{ margin: '0px 20px', marginBottom: 20 }} />
        <Flex fw>
          <ProductTable data={data} />
          <Text
            color='#313fd6' bold size={20} style={{ marginTop: 20 }}
            value={`${months?.[data[data?.length - 1]?.Mese_Descrizione]} ${parseInt(getUrlParams('year')) || new Date().getFullYear()}`}
          />
          <Line options={options} data={chartData} />
        </Flex>
      </Flex>
    </div>
  )
}

const ProductTable = ({ data }) => (
  <>
    <table>
      {!!headerKeys?.length && (
        <thead>
          <tr>
            <td>#</td>
            {headerKeys.map((key) => (
              <td className='firstRow' key={key}>{key}</td>
            ))}
          </tr>
        </thead>)}
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            {keys.map((key, keyN) =>
              <td key={key}>
                {keyN === 0
                  ? `${months[item[key]]} ${getUrlParams('year') || new Date().getFullYear()}`
                  : keyN === 10 ? `${parseFloat(item[key]).toFixed(2)} %` : item[key]}
              </td>)}
          </tr>
        ))}
      </tbody>
    </table>
  </>
)
