import React, { useRef } from 'react'
import clockBig from '../../Resources/clockBig.gif'
import { Icon } from './Icon'
import { Text } from './Text'
import { motion } from 'framer-motion'

const clickButtonVariants = (e) => ({
  idle: { x: 0, y: 0, scale: 1, boxShadow: '0px 4px 13px 3px rgba(100, 100, 100, 0.24)' },
  expanded: { scale: 1, x: 0, y: 0, width: e }
})

const clickButtonNoShadowVariants = (e) => ({
  idle: { x: 0, y: 0, scale: 1 },
  expanded: { scale: 1, x: 0, y: 0, width: e }
})

const ImgSpinner = () =>
  <img alt='loading' src={clockBig} style={{ maxWidth: 50, maxHeight: 30, marginLeft: 10 }} />

export const Button = ({ round, iconSize, disabled, hidden, spinner, icon, label, onClick, style, iconStyle, width, tooltip, inverted, labelProps = {}, noShadow, styleText }) => {
  const tooltipRef = useRef(null)

  if (hidden) return null
  if (spinner) return <ImgSpinner />

  const buttonStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: inverted ? '#32324e' : 'white',
    borderRadius: 20,
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? 0.6 : 1,
    userSelect: 'none',
    padding: !round && label && 6,
    width: width || 'auto',
    overflow: 'hidden',
    ...(label ? { paddingLeft: 20, paddingRight: 20 } : {}),
    ...(round ? { width: 30, height: 30, borderRadius: 15 } : {}),
    ...style
  }

  const iconDefaultStyle = {
    marginTop: -1,
    marginLeft: 6.5
  }

  const expansion = tooltipRef.current?.offsetWidth ? tooltipRef.current?.offsetWidth + 30 : 'auto'
  const variants = noShadow ? clickButtonNoShadowVariants : clickButtonVariants

  const doNothing = () => null

  const onClickFunction = (e) => {
    // Evita il doppio click
    if (e?.detail !== 1) doNothing()
    else onClick(e)
  }

  return (
    <motion.div
      whileHover={round && tooltip && 'expanded'}
      onClick={!disabled ? onClickFunction : undefined}
      animate='idle'
      transition={{ delay: 0.3, duration: 0.5 }}
      style={buttonStyle}
      variants={variants(expansion)}
    >
      {icon ? <Icon name={icon} size={iconSize || 18} style={{ ...iconDefaultStyle, ...iconStyle }} color={inverted ? 'white' : '#32324e'} /> : null}
      {label && !round ? (<Text color={inverted ? 'white' : '#32324e'} bold center value={label} style={{ marginLeft: icon && 15, flexGrow: width ? 1 : 0, textAlign: 'center', ...styleText, width: '100%' }} />) : null}
      {<div ref={tooltipRef}><Text style={{ whiteSpace: 'nowrap', marginLeft: 10, marginRight: 10, display: 'block' }} bold value={tooltip} color={inverted ? 'white' : '#32324e'} {...labelProps} /></div>}
    </motion.div>
  )
}
