import React, { useEffect, useState } from 'react'
import { ChartTitle, Flex, Spinner } from '../../Components'
import { Api, chunkArray, getQueryParams } from '../Functions'

const headerKeys = ['Vessel Name', 'Co2']
const keys = ['Centro_Costo', 'Co2Emission']

export const CarbonFootprint = () => {
  const [data, setData] = useState()
  const [isSuccess, setIsSuccess] = useState(false)

  const loadMonthlyExpensesPerVessel = async () => {
    const { data: { data } } = await Api().get('biDB/getCarbonFootprint', { params: { ...getQueryParams() } })
    setData(chunkArray(data, 24))
    setIsSuccess(true)
  }

  useEffect(() => { loadMonthlyExpensesPerVessel() }, [])

  if (!isSuccess) return <Spinner />
  return data?.map((element, i) => {
    return (
      <div key={i} style={{ width: '100vw', height: '100vh' }}>
        <Flex fw fh>
          <ChartTitle title='Carbon footprint' subTitle='Here below you may find a report of the CO2 emissions associated to each one of your vessels' />
          <Flex fw row>
            <C02Table data={element} />
          </Flex>
        </Flex>
      </div>
    )
  })
}

const C02Table = ({ data }) => {
  return (
    <>
      <table>
        {!!headerKeys?.length && (
          <thead>
            <tr>
              <td>#</td>
              {headerKeys.map((key, ind) => (
                <td className='firstRow' key={ind}>{key}</td>
              ))}
            </tr>
          </thead>)}
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              {keys.map((key, keyN) =>
                <td key={key}>
                  {keyN === 1 ? parseFloat(item[key]).toFixed(2) : item[key]}
                </td>)}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
