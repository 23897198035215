import React from 'react'
import { Flex, Input, Text } from '../../Components'

export function CreateCron ({ day: [day, setDay] }) {
  return (
    <Flex fw>
      <Text value='Data e ora invio' bold title size={22} />
      <Text
        value='Seleziona il giorno del in cui vuoi che il report venga inviato. Ogni mese verrà inviato il report del mese precedente'
        style={{ margin: '10px 0px' }}
      />
      <Flex fw row>
        <Input
          dropdown style={{ marginTop: 10, marginBottom: 10, width: 200, marginRight: 20 }}
          value={day} onChange={({ day }) => setDay(day)} label='Giorno' id='day' options={Array(28).fill(0).map((k, n) => n + 1)}
        />
        <Text value={`Il report verrà inviato ogni ${day}° giorno del mese alle ore 10:00.`} />
      </Flex>
    </Flex>
  )
}
