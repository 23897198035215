import {
  ProductMix, YourPreferredTop10RoutesThisMonth, YourPreferredTop10RoutesYTD, YourPreferredTop20Origins,
  YourPreferredTop20Destinations, Top10Airlines, RepartitionAmongCostCenter, AggregateFigures, ReportTitle, ThanksPage, SeeYouSoon
} from '../../Charts/Business'

export const pagesBusiness = [
  <ReportTitle key={1} />, // page with client name and logo
  <AggregateFigures key={2} />, // page 2 of the report
  <ProductMix key={3} />, // page 3 of the report
  <YourPreferredTop10RoutesThisMonth key={4} />, // page 4 of the report
  <YourPreferredTop10RoutesYTD key={5} />, // page 5 of the report
  <YourPreferredTop20Origins key={6} />, // page 6 of the report
  <YourPreferredTop20Destinations key={7} />, // page 7 of the report
  <Top10Airlines key={8} />, // page 8 of the report
  <RepartitionAmongCostCenter key={9} />, // page 9 of the report
  <ThanksPage key={10} />, // page 10 of the report
  <SeeYouSoon key={11} /> // page 11 of the report
]
