import React, { useEffect, useState } from 'react'
import { ChartTitle, Flex, Spinner, Table } from '../../Components'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { Api } from '../Functions'
import { getUrlParams } from '../../Services/Functions'

const colors = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF', '#800000', '#008000', '#000080', '#808000', '#800080']

ChartJS.register(ArcElement, Tooltip, Legend)

const options = { responsive: true, plugins: { title: { display: false }, legend: { position: 'bottom', align: 'center' } } }

export const Top10Airlines = () => {
  const [chartData, setChartData] = useState()
  const [tableData, setTableData] = useState()
  const [isSuccess, setIsSuccess] = useState(false)

  const loadTop10Airlines = async () => {
    const [clientCode, shipOwnerCode] = getUrlParams(['clientCode', 'shipOwnerCode'])
    const { data: { data } } = await Api()('biDB/getTop10Airlines', { params: { ...(clientCode ? { clientCode } : {}), ...(shipOwnerCode ? { shipOwnerCode } : {}) } })
    const newTableData = [
      ...data.map((d) => ({ Compagnia: d.Compagnia, percentage: parseFloat(d.percentage).toFixed(2) + ' %' })),
      { Compagnia: 'OTHERS', percentage: (100 - parseFloat(data.reduce((acc, d) => acc + parseFloat(d.percentage), 0))).toFixed(2) + ' %' }
    ]
    setTableData(newTableData)
    setChartData({
      labels: newTableData.map((d) => d.Compagnia),
      datasets: [
        {
          label: 'Top 10 airlines',
          data: newTableData.map((d) => parseFloat(d.percentage)),
          backgroundColor: newTableData.map((i, ind) => colors[ind])
        }
      ]
    })
    setIsSuccess(true)
  }

  useEffect(() => { loadTop10Airlines() }, [])

  if (!isSuccess || !chartData || !tableData) return <Spinner />
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Flex fw fh>
        <ChartTitle title='Top 10 Airlines (issued tickets)' subTitle='The list and percentages of the top ten airlines used by your companies. Percentages are calculated in termins of issued tickets.' />
        <Flex style={{ width: '80%' }} row>
          <ProductTable data={tableData} />
          <div style={{ width: 80 }} />
          <Pie data={chartData} options={options} />
        </Flex>
      </Flex>
    </div>
  )
}

const ProductTable = ({ data }) => {
  return <Table data={data} keys={['Compagnia', 'percentage']} headerKeys={['Airlines', '%']} />
}
