import React, { useEffect, useState } from 'react'
import { ChartTitle, Flex, Spinner } from '../../Components'
import { Api, chunkArray, formatPrice, getQueryParams } from '../Functions'
import { getUrlParams } from '../../Services/Functions'

const currentYear = parseInt(getUrlParams('year')) || new Date().getFullYear()
const lastYear = currentYear - 1
const headerKeys = ['Vessel', currentYear, lastYear, currentYear, lastYear, currentYear, lastYear]
const keys = ['Centro_Costo', 'SpeseCorrente', 'SpesePrecedente', 'PaxCorrente', 'PaxPrecedente', 'MediaCorrente', 'MediaPrecedente']

export const AggregateFiguresPerRank = () => {
  const [data, setData] = useState()
  const [isSuccess, setIsSuccess] = useState(false)

  const loadMonthlyExpensesPerVessel = async () => {
    const { data: { data } } = await Api().get('biDB/getAggregateFiguresPerRank', { params: { ...getQueryParams() } })
    setData(chunkArray(data, 24))
    setIsSuccess(true)
  }

  useEffect(() => { loadMonthlyExpensesPerVessel() }, [])

  if (!isSuccess) return <Spinner />
  return data?.map((e, i) => (
    <div key={i} style={{ width: '100vw', height: '100vh' }}>
      <Flex fw fh>
        {i === 0 && (
          <ChartTitle
            title='Aggregate figures per rank'
            subTitle='In the following presentation you will find a description of issued travel services, statistics relating to services offer to your company and financial figures for the considered period.'
          />
        )}
        <Flex fw row>
          <RankTable data={data} />
        </Flex>
      </Flex>
    </div>
  ))
}

const RankTable = ({ data }) => {
  const totalSpeseCorrente = data.reduce((acc, item) => acc + parseFloat(item?.SpeseCorrente || 0), 0)
  const totalSpesePrecedente = data.reduce((acc, item) => acc + parseFloat(item?.SpesePrecedente || 0), 0)
  const totalPaxCorrente = data.reduce((acc, item) => acc + parseFloat(item?.PaxCorrente || 0), 0)
  const totalPaxPrecedente = data.reduce((acc, item) => acc + parseFloat(item?.PaxPrecedente || 0), 0)
  const totalMediaCorrente = data.reduce((acc, item) => acc + parseFloat(item?.MediaCorrente || 0), 0) / data.length
  const totalMediaPrecedente = data.reduce((acc, item) => acc + parseFloat(item?.MediaPrecedente || 0), 0) / data.length

  return (
    <>
      <table>
        {!!headerKeys?.length && (
          <thead>
            <tr>
              <td colSpan='2' style={{ border: '0px' }} />
              <td colSpan='2' style={{ backgroundColor: 'silver', color: '#FFF' }}>Amount</td>
              <td colSpan='2' style={{ backgroundColor: 'silver', color: '#FFF' }}>Pax Nr.</td>
              <td colSpan='2' style={{ backgroundColor: 'silver', color: '#FFF' }}>Avg. Price</td>
            </tr>
            <tr>
              <td>#</td>
              {headerKeys.map((key, ind) => (
                <td className='firstRow' key={ind}>{key}</td>
              ))}
            </tr>
          </thead>)}
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              {keys.map((key, keyN) =>
                <td key={key}>
                  {keyN === 1 || keyN === 2 || keyN === 5 || keyN === 6 ? formatPrice(item[key]) : item[key]}
                </td>)}
            </tr>
          ))}
          <tr>
            <td />
            <td>TOTAL</td>
            <td>{formatPrice(totalSpeseCorrente)}</td>
            <td>{formatPrice(totalSpesePrecedente)}</td>
            <td>{totalPaxCorrente}</td>
            <td>{totalPaxPrecedente}</td>
            <td>{formatPrice(totalMediaCorrente)}</td>
            <td>{formatPrice(totalMediaPrecedente)}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
