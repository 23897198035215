import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import { Flex } from '../Common/Flex'

const years = Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map((year) => ({ value: year, name: year }))
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month, i) => ({ value: i, name: month }))

export function SelectMonthYear ({ year: [selectedYear, setSelectedYear], month: [selectedMonth, setSelectedMonth], onlyPast = true }) {
  const SelectYear = ({ state: [selectedYear, setSelectedYear], style }) => (
    <Dropdown
      value={selectedYear}
      onChange={({ value }) => setSelectedYear(value)}
      options={years} optionLabel='name'
      style={{ ...style }}
    />
  )

  const SelectMonth = ({ state: [selectedMonth, setSelectedMonth], style }) => (
    <Dropdown
      value={selectedMonth}
      onChange={({ value }) => setSelectedMonth(value)}
      options={onlyPast && selectedYear === new Date().getFullYear() ? months.slice(0, new Date().getMonth() + 1) : months} optionLabel='name'
      style={{ ...style }}
    />
  )

  return (
    <Flex row>
      <SelectMonth state={[selectedMonth, setSelectedMonth]} />
      <SelectYear state={[selectedYear, setSelectedYear]} style={{ marginLeft: 10 }} />
    </Flex>
  )
}
