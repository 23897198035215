import React from 'react'

export function Table ({ data = [], keys = [], headerKeys = [], firstColKeys = [], firstColStyle = {}, firstRowStyle = {}, firstCell = '#' }) {
  return (
    <table>
      {!!headerKeys?.length && (
        <thead>
          <tr>
            {!!firstColKeys?.length && (<td>{firstCell}</td>)}
            {headerKeys.map((key) => (
              <td className='firstRow' style={firstRowStyle} key={key}>{key}</td>
            ))}
          </tr>
        </thead>)}
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            {!!firstColKeys[index] && (<td className='firstCol' style={firstColStyle}>{firstColKeys[index]}</td>)}
            {keys.map((key, keyN) => <td key={key}>{item[key]}</td>)}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
