import React, { useEffect, useState } from 'react'
import { ChartTitle, Flex, Spinner } from '../../Components'
import { Api, chunkArray, formatPrice, getQueryParams } from '../Functions'

const keys = ['Cod_Campo05', 'Numero_Totale_Passeggeri', 'Costo_Totale', 'Media_Spesa_Passeggero']

export const AggregateFiguresPerVesselType = () => {
  const [data, setData] = useState()
  const [isSuccess, setIsSuccess] = useState(false)

  const loadMonthlyExpensesPerVessel = async () => {
    const { data: { data } } = await Api().get('biDB/getAggregateFiguresPerVesselType', { params: { ...getQueryParams() } })
    setData(chunkArray(data, 24))

    setIsSuccess(true)
  }

  useEffect(() => { loadMonthlyExpensesPerVessel() }, [])

  if (!isSuccess) return <Spinner />
  return data?.map((element, i) => {
    return (
      <div key={i} style={{ width: '100vw', height: '100vh' }}>

        <Flex fw fh>
          {i === 0 && (
            <ChartTitle
              title='Aggregate figures per vessel type'
              subTitle='In the following presentation you will find a description of issued travel services, statistics relating to services offer to your company and financial figures for the considered period.'
            />
          )}
          <Flex fw style={{ padding: 10 }}>
            <VesselTypesTable data={element} />

          </Flex>
        </Flex>
      </div>
    )
  })
}

const VesselTypesTable = ({ data, offset, limit }) => {
  return (
    <div style={{ marginLeft: 5, marginRight: 5 }}>
      <table>
        <thead>
          <tr>
            <td className='firstRow'>Vessel type</td>
            <td className='firstRow'>Monthly pax</td>
            <td className='firstRow'>Monthly expenses</td>
            <td className='firstRow'>Exps/pax</td>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              {keys.map((key, keyN) =>
                <td key={key}>
                  {keyN === 2 || keyN === 3 ? formatPrice(item[key]) : item[key]}
                </td>)}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
