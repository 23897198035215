import React, { useEffect, useState } from 'react'
import { ChartTitle, Flex, Spinner } from '../../Components'
import { Api, chunkArray, formatPrice, getQueryParams } from '../Functions'
import { getUrlParams } from '../../Services/Functions'

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const monthCurrentYear = (months[parseInt(getUrlParams('month'))] || new Date().getMonth()) + ' ' + (parseInt(getUrlParams('year')) || new Date().getFullYear())
const monthLastYear = (months[parseInt(getUrlParams('month'))] || new Date().getMonth()) + ' ' + (parseInt(getUrlParams('year')) - 1 || new Date().getFullYear() - 1)
const headerKeys = ['Vessel', monthCurrentYear, monthLastYear, monthCurrentYear, monthLastYear, monthCurrentYear, monthLastYear]
const keys = ['Centro_Costo', 'SpeseCorrente', 'SpesePrecedente', 'PaxCorrente', 'PaxPrecedente', 'MediaCorrente', 'MediaPrecedente']
const LIMIT = 24
export const MontlhyExpensesPerVessel = () => {
  const [data, setData] = useState()
  const [isSuccess, setIsSuccess] = useState(false)
  const [totals, setTotals] = useState(null)

  const loadMonthlyExpensesPerVessel = async () => {
    const { data: { data } } = await Api().get('biDB/getExpensesPerVesselByMonth', { params: { ...getQueryParams() } })
    const totalSpeseCorrente = data.reduce((acc, item) => acc + parseFloat(item?.SpeseCorrente || 0), 0)
    const totalSpesePrecedente = data.reduce((acc, item) => acc + parseFloat(item?.SpesePrecedente || 0), 0)
    const totalPaxCorrente = data.reduce((acc, item) => acc + parseFloat(item?.PaxCorrente || 0), 0)
    const totalPaxPrecedente = data.reduce((acc, item) => acc + parseFloat(item?.PaxPrecedente || 0), 0)
    const totalMediaCorrente = data.reduce((acc, item) => acc + parseFloat(item?.MediaCorrente || 0), 0) / data.length
    const totalMediaPrecedente = data.reduce((acc, item) => acc + parseFloat(item?.MediaPrecedente || 0), 0) / data.length

    const totalsPayload = {
      totalMediaCorrente,
      totalMediaPrecedente,
      totalPaxCorrente,
      totalPaxPrecedente,
      totalSpeseCorrente,
      totalSpesePrecedente
    }
    setTotals(totalsPayload)
    setData(chunkArray(data, LIMIT))
    setIsSuccess(true)
  }

  useEffect(() => { loadMonthlyExpensesPerVessel() }, [])

  if (!isSuccess) return <Spinner />
  return data?.map((element, i) => {
    return (
      <div key={i} style={{ width: '100vw', height: '100vh' }}>
        <Flex fw fh>
          {i === 0 && (
            <ChartTitle
              title='Monthly expenses per vessel'
              subTitle='In the following presentation you will find your montlhy expenses and comparison between current period and last year period.'
            />
          )}
          <Flex fw row>
            <VesselTable data={element} offset={LIMIT * i} totals={i === data?.length - 1 ? totals : false} />
          </Flex>
        </Flex>
      </div>
    )
  })
}

const VesselTable = ({ data, totals, offset }) => {
  return (
    <>
      <table>
        {!!headerKeys?.length && (
          <thead>
            <tr>
              <td colSpan='2' style={{ border: '0px' }} />
              <td colSpan='2' style={{ backgroundColor: 'silver', color: '#FFF' }}>Amount</td>
              <td colSpan='2' style={{ backgroundColor: 'silver', color: '#FFF' }}>Pax Nr.</td>
              <td colSpan='2' style={{ backgroundColor: 'silver', color: '#FFF' }}>Avg. Price</td>
            </tr>
            <tr>
              <td>#</td>
              {headerKeys.map((key, ind) => (
                <td className='firstRow' key={ind}>{key}</td>
              ))}
            </tr>
          </thead>)}
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{offset + index + 1}</td>
              {keys.map((key, keyN) =>
                <td key={key}>
                  {keyN === 1 || keyN === 2 || keyN === 5 || keyN === 6 ? formatPrice(item[key]) : item[key]}
                </td>)}
            </tr>
          ))}
          {totals && (
            <tr>
              <td />
              <td>TOTAL</td>
              <td>{formatPrice(totals?.totalSpeseCorrente)}</td>
              <td>{formatPrice(totals?.totalSpesePrecedente)}</td>
              <td>{totals?.totalPaxCorrente}</td>
              <td>{totals?.totalPaxPrecedente}</td>
              <td>{formatPrice(totals?.totalMediaCorrente)}</td>
              <td>{formatPrice(totals?.totalMediaPrecedente)}</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}
