import { useState } from 'react'
import { Button, Flex, Modal, Text } from '../../Components'
import { SearchClient } from '../../Components/SearchClient'
import { ClientsTable } from './ClientsAndShipTable'

export const ClientList = ({ clientsList: [clientsList, setClientsList] }) => {
  const [modalAddVisible, setModalAddVisible] = useState(false)

  const onSelectClient = (client) => {
    const clientAlreadyInList = clientsList.find((c) => c.codCliente12 === client.codCliente12)
    if (clientAlreadyInList) return window.growl.show({ severity: 'error', summary: 'Attenzione', detail: 'Il cliente è già presente nella lista' })
    setClientsList([...clientsList, client])
  }

  return (
    <Flex fw>
      <Modal
        header='Ricerca e selezione cliente' noDismissable hideClose visible={modalAddVisible}
        onHide={() => setModalAddVisible(false)} style={{ width: '80%', maxHeight: '80%' }}
      >
        <Flex fw fh style={{ backgroundColor: 'rgba(231,231,231)', padding: 20, overflow: 'auto', borderRadius: 20 }}>
          <SearchClient onSelectClient={onSelectClient} hideModal={() => setModalAddVisible(false)} />
        </Flex>
      </Modal>
      <Flex fw row style={{ marginTop: 20 }}>
        <Flex style={{ marginLeft: '25%', width: '50%' }}>
          <Text value='Clienti' bold title size={22} />
        </Flex>
        <Flex ae style={{ width: '25%' }}>
          <Button label='Aggiungi' icon='plus' onClick={() => setModalAddVisible(true)} />
        </Flex>
      </Flex>
      <ClientsTable clientsList={[clientsList, setClientsList]} />
    </Flex>
  )
}
