import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { FC } from '../../Services'
// import { useAuth } from '../../Provider/AuthProvider'

import { TopMenu } from '../../Components/'
import { PageContainer } from '../../Components/Common/PageContainer'

function ProtectedRoute ({ children }) {
  // const { onLogout } = useAuth()
  const location = useLocation()
  if (!FC.authenticated) return <Navigate to='/login' replace state={{ from: location }} />

  return (
    <>
      <TopMenu />
      <PageContainer>
        <Outlet />
      </PageContainer>
    </>
  )
}

export default ProtectedRoute
