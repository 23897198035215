import { Flex } from '../Common/Flex'
import { Text } from '../Common/Text'
import { motion } from 'framer-motion'

const CardLine = ({ title, value, bold }) => (
  <Flex row as>
    <Flex as width={60}>
      <Text bold value={title} size={12} />
    </Flex>
    <div style={{ width: 20 }} />
    <Flex as width={170}>
      <Text bold={bold} value={value} size={12} />
    </Flex>
  </Flex>
)

export const ClientBox = ({ client, onSelect = () => {}, isSelected }) => {
  const { codCliente12, nome, cognome, ragioneSociale, localita, provincia, indirizzo, cap, email, codiceFiscale } = client
  return (
    <motion.div
      transition={{ duration: 0.5 }} animate={{ opacity: 1, height: 225 }}
      style={{ ...styles.clientCard(isSelected) }} initial={{ opacity: 0, height: 50 }} onClick={() => onSelect(client)}
    >
      <CardLine title='Codice Cliente:' value={codCliente12} />
      <CardLine title='Nome:' value={nome} />
      <CardLine title='Cognome:' value={cognome} />
      <CardLine title='Ragione Sociale:' value={ragioneSociale} />
      <CardLine title='Località:' value={localita} />
      <CardLine title='Provincia:' value={provincia} />
      <CardLine title='Indirizzo:' value={indirizzo} />
      <CardLine title='CAP:' value={cap} />
      <CardLine title='Email:' value={email} />
      <CardLine title='Codice Fiscale:' value={codiceFiscale} />
    </motion.div>
  )
}

const styles = {
  clientCard: (isSelected) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: isSelected ? '#eaef66' : 'rgba(255, 255, 255, 1)',
    padding: 10,
    width: 250,
    borderRadius: 20,
    margin: 10,
    boxShadow: '10px 10px 20px 0px  rgba(100, 100, 100, 0.24)',
    cursor: isSelected ? 'inherit' : 'pointer'
  })
}
